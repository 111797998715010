import React from 'react';

interface AppSocialIconProps {
  name?: string;
  uri?: string;
  icon?: 'twitter' | 'linkedin' | 'github';
  hasBackground?: boolean
}

const TwitterIcon = () => (
  <g>
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
  </g>
);

const LinkedInIcon = () => (
  <>
    <path d="M.81,7.95a.75.75,0,0,0-.75.75V22.63a.75.75,0,0,0,.75.75H5.67a.75.75,0,0,0,.75-.75V8.7a.75.75,0,0,0-.75-.75ZM4.92,21.88H1.56V9.45H4.92Z" />
    <path d="M17.89,7.94a7.35,7.35,0,0,0-3.53.93V8.7a.75.75,0,0,0-.75-.75H8.51a.75.75,0,0,0-.75.75V22.63a.75.75,0,0,0,.75.75h5.11a.75.75,0,0,0,.75-.75V15.38a1.39,1.39,0,0,1,2.79,0v7.25a.75.75,0,0,0,.75.75h5.36a.75.75,0,0,0,.75-.75V15.5C24,10.83,21.67,7.94,17.89,7.94Zm4.62,13.94H18.65v-6.5a2.89,2.89,0,0,0-5.79,0v6.5H9.26V9.45h3.61v.84a.75.75,0,0,0,1.26.55,6.07,6.07,0,0,1,3.77-1.4c2.93,0,4.62,2.21,4.62,6.06Z" />
    <path d="M3.24,7.13A3.25,3.25,0,1,0,0,3.88,3.25,3.25,0,0,0,3.24,7.13Zm0-5A1.75,1.75,0,1,1,1.49,3.88,1.75,1.75,0,0,1,3.24,2.13Z" />
  </>
);

const GithubIcon = () => (
  <path d="M12,0A12,12,0,0,0,8.59,23.5a1.25,1.25,0,0,0,1.61-1.2V20a.75.75,0,0,0-.91-.73A2,2,0,0,1,6.66,18l-.11-.27.18.13a2.62,2.62,0,0,0,3.06.5.75.75,0,0,0,.44-.58,4.84,4.84,0,0,1,.38-1.36.75.75,0,0,0-.41-1.31c-2.4-.27-3.85-.66-3.85-3.66A3.1,3.1,0,0,1,7.18,9.3a.75.75,0,0,0,.14-.79,2.65,2.65,0,0,1-.06-1.57A5.46,5.46,0,0,1,9,7.81a.76.76,0,0,0,.62.1,8.76,8.76,0,0,1,2.28-.31,8.67,8.67,0,0,1,2.29.31.75.75,0,0,0,.62-.1,5.63,5.63,0,0,1,1.76-.87,2.66,2.66,0,0,1-.05,1.57.75.75,0,0,0,.14.79,3.09,3.09,0,0,1,.82,2.15c0,3-1.46,3.38-3.86,3.65a.75.75,0,0,0-.64.52.74.74,0,0,0,.22.79,3.61,3.61,0,0,1,.43,1.77v4.17a1.25,1.25,0,0,0,1.25,1.25,1.19,1.19,0,0,0,.34,0A12,12,0,0,0,12,0Zm3.16,22V18.18a5.94,5.94,0,0,0-.27-1.72c1.93-.31,4.12-1.18,4.12-5a4.62,4.62,0,0,0-.93-2.83,4.52,4.52,0,0,0-.24-2.73.75.75,0,0,0-.47-.44c-.26-.08-1.24-.25-3.09.92a10.19,10.19,0,0,0-4.7,0c-1.85-1.18-2.83-1-3.09-.92A.75.75,0,0,0,6,5.9a4.51,4.51,0,0,0-.24,2.73,4.62,4.62,0,0,0-.93,2.83c0,3.83,2.21,4.69,4.14,5-.07.24-.13.47-.17.66-.42.11-.65-.06-1.2-.47a7.19,7.19,0,0,0-2.81-1.38.75.75,0,0,0-.74,1.22,5.23,5.23,0,0,1,1.17,2A3.52,3.52,0,0,0,8.7,20.89V22a10.5,10.5,0,1,1,6.46,0Z" />
);

const RenderIcon = ({ icon }: Pick<AppSocialIconProps, 'icon'>) => {
  if (icon === 'github') {
    return (<GithubIcon />)
  }
  if (icon === 'linkedin') {
    return <LinkedInIcon />;
  }
  return <TwitterIcon />
};


const AppSocialIcon: React.FC<AppSocialIconProps> = ({
  uri,
  name,
  icon = 'twitter',
  hasBackground = true
}) => {
  return (
    <a
      className={`
        flex content-center items-center 
        justify-center text-bwhite 
        rounded-full overflow-hidden 
        relative transition h-[70px] 
        w-[70px] hover:text-bwhite 
        hover:scale-110 
        ${hasBackground ? 'is-dark-background' : ''}`
      }
      href={uri}
      title={icon || 'twitter'}
      target="_blank"
      rel="noreferrer"
    >
      <svg
        className="relative"
        viewBox="0 0 24 24"
        width="32px"
        height="32px"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>{icon?.toUpperCase()}</title>
        <RenderIcon icon={icon} />
      </svg>
    </a>
  );
};

export default AppSocialIcon;
